import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ClickhouseService } from './shared/services/clickhouse.service';
import { SocketService } from './shared/services/socket.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from './store/state/app.state';
import { eventIdSelector, uuidSelector } from './store/selectors/profile.selectors';
import {
  setCancellationFlowIsActive,
  setCanFastActivateSubscription,
  setIsFreeUserRegistered,
  setIsUserCompliance,
  setProfilePage,
  setCoinsPackTest,
} from './store/actions/event-variables.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private clickHouse: ClickhouseService,
    private socketService: SocketService,
    private store: Store<AppStateInterface>,
  ) {}

  public ngOnInit(): void {
    this.socketService.connect();
    this.socketService.on('connect').subscribe(() => {
      combineLatest([
        this.store.select(uuidSelector),
        this.store.select(eventIdSelector),
      ]).subscribe(([uuid, eventId]) => {
        if (uuid && eventId) {
          this.socketService.emit('join', {
            type: 'web',
            channel: uuid,
            eventId: eventId,
          });
          combineLatest([
            this.clickHouse.showEvent('abtestcancelflow'),
            this.clickHouse.showEvent('free_user_registered'),
            this.clickHouse.showEvent('abtestupgradesub'),
            this.clickHouse.showEvent('user_compliance'),
            this.clickHouse.showEvent('marketing_test_profilepage'),
            this.clickHouse.showEvent('marketing_test_coinspack'),
          ]).subscribe(
            ([abtestcancelflow, isUserFree, abtestupgradesub, isUserCompliance, marketing_test_profilepage, marketing_test_coinspack]) => {
              this.store.dispatch(
                setCancellationFlowIsActive({
                  value: abtestcancelflow.data?.subevents.length
                    ? abtestcancelflow.data.subevents[0].value === 'true'
                    : false,
                }),
              );
              this.store.dispatch(
                setIsFreeUserRegistered({
                  value: isUserFree.data?.subevents.length
                    ? isUserFree.data.subevents[0].value === 'true'
                    : false,
                }),
              );
              this.store.dispatch(
                setCanFastActivateSubscription({
                  value: abtestupgradesub.data?.subevents.length
                    ? abtestupgradesub.data.subevents[0].value === 'true'
                    : false,
                }),
              );
              this.store.dispatch(
                setIsUserCompliance({
                  value: isUserCompliance.data?.subevents.length
                    ? isUserCompliance.data.subevents[0].value === 'compliance'
                    : false,
                }),
              );
              this.store.dispatch(
                setProfilePage({
                  value: marketing_test_profilepage.data?.subevents.length
                    ? marketing_test_profilepage.data.subevents[0].value === 'true'
                    : false,
                }),
              );
              this.store.dispatch(
                setCoinsPackTest({
                  value: marketing_test_coinspack.data?.subevents.length
                    ? marketing_test_coinspack.data.subevents[0].value === 'new_39'
                    : false,
                }),
              );
            },
          );
        }
      });
    });
  }
}
