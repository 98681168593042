import { EventVariablesStoreInterface } from '../models/event-variables-store.interface';

export const initialEventVariablesState: EventVariablesStoreInterface = {
  canFastActivateSubscription: false,
  cancellationFlowIsActive: false,
  isFreeUserRegistered: false,
  isUserCompliance: false,
  isProfilePage: false,
  isCoinsPackTest: false,
};
